<template>
  <div class="circlenotice" v-if="this.ninfodata!==null">
    <div class="cir_nav">
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/circle' }">教研圈</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/circleintroduce',query:{introId: this.ninfodata.researchId} }">{{ninfodata.researchCircleName}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ninfodata.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
    </div>
    <div class="circlenotice_center">
      <header>
        <p>{{ninfodata.title}}</p>
      </header>
      <aside>
        <p>
          <span>发布者：{{ninfodata.createUser}}</span>
          <span class="side_span">发布时间：{{ninfodata.createTime}}</span>
        </p>
      </aside>
      <main>
        <div v-html="ninfodata.content"></div>
        <!-- <p >{{ninfodata.content}}</p> -->
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ninfodata: null,
    };
  },
  created() {
    console.log(this.$route.query.id, "公告路由");
    this.queryOneNotice(this.$route.query.id);
  },
  methods: {
    // 查询单个公告
    async queryOneNotice(id) {
      let from = new FormData();
      from.append("noticeId", id);
      const { data, status } = await this.$Http.queryOneNotice(from);
      if (status == 200) {
        console.log(data, status, "查询单个公告");
        this.ninfodata = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.circlenotice {
  .cir_nav {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .circlenotice_center {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    header {
      // width: 600px;
      // margin: 0 auto;
      display: flex;
      justify-content: center;
      padding-top: 50px;
      p {
        // width: 528px;
        // height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 26px;
      }
    }
    aside {
      // width: 600px;
      // margin: 0 auto;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      span {
        // width: 98px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
      }
      .side_span {
        display: inline-block;
        margin-left: 20px;
      }
    }
    main {
      width: 960px;
      margin: 0 auto;
      // padding: 0 50px;
      // display: flex;
      // justify-content: center;
      //   margin-top: 80px;
      padding: 40px 0;
      // p {
      //   // width: 524px;
      //   // height: 28px;
      //   font-size: 16px;
      //   font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //   font-weight: 400;
      //   color: #141516;
      //   line-height: 40px;
      // }
    }
  }
}
</style>